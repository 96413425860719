import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { graphql } from 'gatsby';
import { colors } from "../utils/presets";
export const pageQuery = graphql`
query Index {
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`;
const layoutProps = {
  pageQuery
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);

    this.layout = ({
      children,
      ...props
    }) => <div css={`margin-top: 3rem;`}>
    {children}
  </div>;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="p" components={components}>{`The `}<MDXTag name="strong" components={components} parentName="p">{`I Ching`}</MDXTag>{` or the `}<MDXTag name="strong" components={components} parentName="p">{`Book of Changes`}</MDXTag>{` has been used for over 3,000 years as a
philosophical tool for introspection and decision making. Its practical guidance is
still relevant now. The imagery and wisdom within the text is rooted in nature and
naturally occurring rhythms and phenomena; each passage provides an image that serves
as an analogy for a human situation or condition offering possible insight or course
of action.`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
    